import styled from "styled-components"
import { formatDate } from "@/utils/date.utils"
import { useState, useEffect } from "react"

import Image, { ImageProps } from "next/image"
import Link from "next/link"

export interface IProps {
  image: ImageProps
  title: string
  description: string
  link: string
  date: string | Date
  newDate?: boolean
}

export default function Card({
  image,
  title,
  // description,
  link,
  date,
  newDate,
}: IProps) {
  const [state, setState] = useState({
    updatedAt: "",
  })

  useEffect(() => {
    setState({
      updatedAt: formatDate(!newDate ? new Date(date) : date),
    })
  }, [date, newDate])

  return (
    <Link href={link}>
      <Container>
        <Figure>
          <Image {...image} alt={title} />
        </Figure>
        <ContentContainer>
          <DateLabel>{state.updatedAt}</DateLabel>
          <Title>{title}</Title>
          {/* <Description>{description}</Description> */}
        </ContentContainer>
      </Container>
    </Link>
  )
}

const DateLabel = styled.h3`
  color: ${({ theme }) => theme.fonts.primary};
  font-weight: 300;
  font-size: 1.3rem;
  transition: color 0.2s ease-in-out;
`

const Title = styled.h3`
  font-weight: 300;
  color: ${({ theme }) => theme.fonts.secondary};
  transition: color 0.2s ease-in-out;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.3rem;
  height: 100%;

  &:hover {
    ${Title} {
      text-decoration: underline;
    }
  }
`

const Figure = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    box-shadow: 0 0.6rem 2.6rem 0.2rem rgba(0, 0, 0, 0.25);
  }
`

const ContentContainer = styled.div`
  padding: 1.5rem 0;
  flex-grow: 1;
`

const Description = styled.p``
