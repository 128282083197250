import styled from 'styled-components'

import Header from '@/components/header/header.component'
import Footer from '@/components/footer/footer.component'

import { ICategory } from '@/types/category.types'
export interface IProps {
  children: React.ReactNode | React.ReactNode[]
  categories: ICategory[]
}

export default function PageLayout({ children, categories }: IProps) {
  return (
    <Layout>
      <Header categories={categories} />
      <Main>{children}</Main>
      <Footer categories={categories} />
    </Layout>
  )
}

const Layout = styled.div`
  #header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    max-width: 120rem;
    margin: 0 auto;
    height: 100%;
  }

  #footer {
    background-color: ${({ theme }) => theme.colors.primary};
    padding: 5rem 0 2rem;
  }
`

const Main = styled.main`
  max-width: 120rem;
  padding: 10rem 2rem;
  margin: 0 auto;
  overflow: hidden;
`
