import styled from 'styled-components'
import { useState, useEffect, useRef } from 'react'
import { formatDate } from '@/utils/date.utils'
import {
  motion,
  PanInfo,
  AnimatePresence,
  useAnimation,
  // AnimationDefinition,
} from 'framer-motion'

import Image from 'next/image'
import Link from 'next/link'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'

import { IPost } from '@/types/post.types'

import placeholderImage from '../../../public/assets/card/card-placeholder-light.svg'

export interface IProps {
  posts: IPost[]
}

export interface IState {
  activeIndex: number
  max: number
  posts: IPost[]
}

export default function HeroSection({ posts }: IProps) {
  const dragContainerRef = useRef<HTMLDivElement>(null)

  const [state, setState] = useState<IState>({
    activeIndex: 0,
    max: posts.length - 1,
    posts: [],
  })

  const controls = useAnimation()
  const contentControls = useAnimation()

  const startImageSwipeAnimations = async (cntrl: any) => {
    controls
      .start(cntrl)
      .then(() => {
        return controls.start({
          x: 0,
          opacity: 0,
          transition: {
            duration: 0.1,
          },
        })
      })
      .then(() => {
        return controls.start({
          x: 0,
          opacity: 1,
          transition: {
            duration: 0.3,
          },
        })
      })
  }
  const startContentSwipeAnimation = async (cntrl: any) => {
    contentControls
      .start(cntrl)
      .then(() => {
        return contentControls.start({
          x: 0,
          transition: {
            duration: 0.3,
          },
          opacity: 0,
        })
      })
      .then(() => {
        return contentControls.start({
          x: 0,
          transition: {
            duration: 0.3,
          },
          opacity: 1,
        })
      })
  }

  const onPrev = () => {
    if (state.activeIndex === 0) return

    startImageSwipeAnimations({
      x: -700,

      transition: {
        duration: 0.4,
      },
      opacity: 0,
    })
    startContentSwipeAnimation({
      transition: {
        duration: 0.2,
      },
      opacity: 0,
    })
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        activeIndex: prevState.activeIndex - 1,
      }))
    }, 400)
  }

  const onNext = () => {
    if (state.activeIndex === state.max) return
    startImageSwipeAnimations({
      x: 700,
      transition: {
        duration: 0.4,
      },
      opacity: 0,
    })
    startContentSwipeAnimation({
      transition: {
        duration: 0.2,
      },
      opacity: 0,
    })

    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        activeIndex: prevState.activeIndex + 1,
      }))
    }, 400)
  }

  const handleDragEnd = async (
    event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo
  ) => {
    if (info.offset.x > 0) {
      onNext()
    } else {
      if (state.activeIndex === 0) return
      onPrev()
    }
  }

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      posts: posts.map((post) => ({
        ...post,
        createdAt: formatDate(post.publishedAt),
      })),
    }))
  }, [posts])

  return (
    <Section>
      <AnimatePresence mode="wait">
        <Grid>
          <GridItem className="content" animate={contentControls}>
            <DateLabel>
              {state.posts?.[state.activeIndex]?.createdAt.toString()}
            </DateLabel>
            <Link
              href={
                state.posts?.[state.activeIndex]?.isPublic
                  ? `/blog/${state.posts?.[state.activeIndex]?.slug}`
                  : state.posts?.[state.activeIndex]?.url ?? '#'
              }
            >
              <H1>{state.posts?.[state.activeIndex]?.title}</H1>
            </Link>
            <ButtonsContainer>
              <ArrowButton
                disabled={state.activeIndex === 0}
                whileHover={{
                  scale: state.activeIndex === 0 ? 1 : 1.03,
                }}
                whileTap={{
                  scale: state.activeIndex === 0 ? 1 : 1.01,
                }}
                onClick={onPrev}
              >
                <AiOutlineLeft />
              </ArrowButton>

              <ArrowButton
                disabled={state.activeIndex === state.max}
                whileHover={{
                  scale: 1.03,
                }}
                whileTap={{
                  scale: 1.01,
                }}
                onClick={onNext}
              >
                <AiOutlineRight />
              </ArrowButton>
            </ButtonsContainer>
          </GridItem>
          <GridItem className="image" ref={dragContainerRef}>
            <Figure
              drag="x"
              dragConstraints={dragContainerRef}
              onDragEnd={handleDragEnd}
              animate={controls}
              whileHover={{
                cursor: 'grabbing',
                scale: 1.05,
                transition: {
                  duration: 0.3,
                },
              }}
              whileTap={{
                cursor: 'grabbing',
                scale: 1.03,
                transition: {
                  duration: 0.3,
                },
              }}
            >
              <Image
                src={
                  state.posts?.[state.activeIndex]?.meta?.image ??
                  placeholderImage
                }
                alt={
                  state.posts?.[state.activeIndex]?.meta?.title ??
                  state.posts?.[state.activeIndex]?.title ??
                  'placeholder'
                }
                width={250}
                height={250}
              />
            </Figure>
          </GridItem>
        </Grid>
      </AnimatePresence>
    </Section>
  )
}

const Section = styled.section`
  margin-bottom: 10rem;
  min-height: 100vh;
`

const DateLabel = styled.p`
  margin-bottom: 0.5rem;
`

const H1 = styled(motion.h1)`
  font-size: 3.2rem;
  line-height: 1.1;
  color: ${({ theme }) => theme.fonts.secondary};
  margin-bottom: 1.5rem;

  &:hover {
    text-decoration: underline;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 5.2rem;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

const ArrowButton = styled(motion.button)`
  cursor: pointer;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.fonts.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  border-radius: 50%;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

const Grid = styled(motion.div)`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  gap: 5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const GridItem = styled(motion.div)`
  &.content {
    grid-row: 2/3;
  }
  &.image {
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    &.content {
      grid-row: unset;
    }
  }
`

const Figure = styled(motion.figure)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;

  img {
    pointer-events: none;
    width: 100%;
    height: 100%;
    object-fit: contain;
    box-shadow: 0 0.6rem 1.6rem 1rem rgba(0, 0, 0, 0.25);
  }
`
