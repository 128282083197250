import { useState } from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

import Link from 'next/link'
import Card from '../card/card.component'
import { Swiper, SwiperSlide } from 'swiper/react'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import { Controller } from 'swiper'

import placeholderImage from '../../../public/assets/card/card-placeholder-light.svg'
// import placeholderImage from "../../../public/assets/card/card-placeholder.svg"

import ISwiper from 'swiper/swiper'
import { IPost } from '@/types/post.types'
export interface IProps {
  title: string
  categoryLink: string
  posts: IPost[]
}

import 'swiper/css'

const CardDirectory = ({ posts, title, categoryLink }: IProps) => {
  const [controlledSwiper, setControlledSwiper] = useState<ISwiper | null>(null)

  const handleClickNext = () => {
    controlledSwiper?.slideNext()
  }

  const handleClickPrev = () => {
    controlledSwiper?.slidePrev()
  }

  return (
    <Container>
      <SectionHead>
        <Link href={categoryLink}>
          <Title>{title}</Title>
        </Link>
        {controlledSwiper && (
          <div>
            <Swiper
              modules={[Controller]}
              controller={{ control: controlledSwiper }}
            >
              <ButtonsContainer>
                <ArrowButton onClick={handleClickPrev}>
                  <figure>
                    <AiOutlineLeft />
                  </figure>
                  <span
                    style={{
                      position: 'absolute',
                      visibility: 'hidden',
                      opacity: 0,
                      display: 'none',
                    }}
                  >
                    menu
                  </span>
                </ArrowButton>
                <ArrowButton onClick={handleClickNext}>
                  <figure>
                    <AiOutlineRight />
                  </figure>
                  <span
                    style={{
                      position: 'absolute',
                      visibility: 'hidden',
                      opacity: 0,
                      display: 'none',
                    }}
                  >
                    menu
                  </span>
                </ArrowButton>
              </ButtonsContainer>
            </Swiper>
          </div>
        )}
      </SectionHead>
      <Directory>
        <AnimatePresence>
          <Swiper
            // className="main-swipe"
            modules={[Controller]}
            onSwiper={setControlledSwiper}
            spaceBetween={70}
            slidesPerView={1}
            breakpoints={{
              425: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
              1000: {
                slidesPerView: 4,
              },
            }}
          >
            {posts.map((post, idx) => (
              <SwiperSlide key={idx}>
                <Card
                  key={post.id}
                  image={{
                    ...placeholderImage,
                    src: post.meta?.image ?? placeholderImage,
                    alt: post.meta?.image ?? post.title,
                    placeholder: 'blur',
                    blurDataURL: placeholderImage.src,
                  }}
                  title={post.title}
                  description={post.meta?.description ?? ''}
                  link={post.isPublic ? `/blog/${post.slug}` : post.url ?? ''}
                  date={post.publishedAt ?? ''}
                  newDate
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </AnimatePresence>
      </Directory>
    </Container>
  )
}

export default CardDirectory

const Container = styled.div`
  margin-bottom: 5rem;
`

const SectionHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
  gap: 2rem;
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

const ArrowButton = styled.button`
  cursor: pointer;
  display: block;
  background-color: transparent;
  border: none;
  border: 1px solid ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.fonts.primary};
  font-size: 1.8rem;
  padding: 0.8rem;
  border-radius: 50%;

  figure {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const Title = styled.h2`
  font-size: 3rem;
  margin-bottom: 2rem;
  font-weight: 400;
  color: ${({ theme }) => theme.fonts.primary};

  &:hover {
    text-decoration: underline;
  }
`

const Directory = styled.div`
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  grid-gap: 4rem; */
`
