export const formatDate = (timestamp?: Date | string) => {
  if (!timestamp) return ""

  const formatedDate = new Date(Number(timestamp)).toLocaleDateString("sk-SK", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  })

  return formatedDate
}
